<template>
  <v-dialog v-model="dialog" scrollable max-width="959">
    <v-card>
      <v-card-title class="px-4" v-html="title">
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pl-1 pr-3 pb-0">
        <v-textarea
          v-model="json"
          hide-details
          flat
          solo
          class="eos-import-export-modal__textarea"
        />
      </v-card-text>

      <v-card-actions class="px-3 py-4">
        <v-spacer />
        <v-btn text @click="closeDialog">Close</v-btn>
        <v-btn text @click="downloadJSON">Download</v-btn>
        <v-btn
          v-show="allowUpdate"
          :loading="showLoader"
          color="success"
          @click="$emit('update-data', json)"
        >Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AppDialogJSONImportExport',
  props: {
    title: {
      type: String,
      required: true
    },
    dataJSON: {
      type: String,
      default: ''
    },
    allowUpdate: {
      type: Boolean,
      default: true
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true,
      json: this.dataJSON
    }
  },
  watch: {
    dataJSON (json) {
      this.json = json
    }
  },
  methods: {
    ...mapActions(['setMessage']),
    closeDialog () {
      this.dialog = false
      this.$emit('close')
    },
    downloadJSON () {
      const filename = `${this.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.json`
      const element = document.createElement('a')
      element.setAttribute(
        'href',
        `data:text/plain;charset=utf-8, ${encodeURIComponent(this.json)}`
      )
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)

      this.$analytics('downloaded_json', { title: this.title })
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-import-export-modal__textarea {
  ::v-deep textarea {
    margin-top: 0px !important;
    padding: 0px 8px;
    height: calc(100vh - 300px);
    border: 1px solid #ddd;
    resize: none;
  }
}
</style>
