<template>
  <v-alert dense type="error" class="mx-5 mb-3 ma-1">
    <v-row class="ma-0 pa-0" align="center">
      <v-col class="grow ma-0 pa-0">
        <span v-html="`This ${entity} is available to anybody with the link. Lock it down
        to ${this.userType} when ready.`"></span>
      </v-col>
      <v-col class="shrink ma-0 pa-0">
        <v-btn depressed color="secondary" dark @click="$emit('make-private')">Make Private</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AppVisibilityAlert',
  props: {
    entity: {
      type: String,
      required: true
    },
    userType: {
      type: String,
      default: 'users'
    }
  }
}
</script>
