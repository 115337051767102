<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="openDialogUsersList"
        >
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-btn>
      </template>
      <span>Manage permissions</span>
    </v-tooltip>

    <v-dialog persistent scrollable v-model="showDialogUsersList" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline text-capitalize" v-html="`Manage ${entity} permissions`"></span>
        </v-card-title>

        <AppVisibilityAlert
          v-if="visibility === 'public'"
          :entity="entity"
          @make-private="$emit('make-private')"
        />

        <v-divider />
        <v-card-text class="py-3">
          <span class="black--text subtitle-1">Whitelist all email addresses by domain</span>
          <v-textarea
            v-model.trim="whitelistedDomains"
            class="mt-2"
            rows="1"
            placeholder="e.g. teamwork.com, gmail.com"
            hint="You can add multiple domains separated by comma (,)"
            autofocus
          />

          <div class="mt-5 mb-8 text-center black--text eos-permission__separator">
            <span class="px-2">OR</span>
          </div>

          <span class="black--text subtitle-1" v-html="`Add individuals who can access the ${entity}`" ></span>
          <br />

          <v-list v-if="hasUsers">
            <v-list-item
              v-for="(user, index) in sortedUsers"
              :key="`user-permission-list-${user.email}`"
              class="px-0 eos-permission__list-item"
              @click="showDialogAddEditUser(index)"
            >
              <v-list-item-avatar>
                <AppAvatar
                  :src="user.photoURL"
                  :name="user.displayName"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ user.displayName }}
                  <v-tooltip v-if="user.email === 'anonymous@instantagencytools.com'" top max-width="375px">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <ul class="py-2 px-3">
                      <li>
                        <template v-if="visibility === 'private'">
                          Someone has visited your <span class="text-lowercase">{{ entity }}</span> without logging in while this tool's permission was set to <b>Public</b>.
                        </template>
                        <template v-else>
                          Someone has visited your <span class="text-lowercase">{{ entity }}</span> without logging in. Please set the visibility to <b>Private</b> in order to restrict access.
                        </template>
                      </li>
                      <li v-if="twSyncOptions.accessToken && twSyncOptions.projectId && twSyncOptions.tasklistId">
                        You may have one or more to-do/key priority assigned on Teamwork to <b>Anyone</b> or to a user not part of this <span class="text-lowercase">{{ entity }}</span>
                      </li>
                    </ul>
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item-content>

              <slot name="actions" v-bind="{ user, index }"></slot>

              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      :style="{ visibility: disableDeleteForEmails.includes(user.email) ? 'hidden' : 'visible' }"
                      @click.stop="confirmUserDelete(index)"
                    >
                      <v-icon color="grey lighten-1">{{ `mdi-${canLeave(user) ? 'logout' : 'delete'}` }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ canLeave(user) ? `Leave ${entity}` : 'Revoke Access' }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-btn v-else depressed class="d-flex mx-auto mt-5" @click="showDialogAddEditUser()">
            <v-icon x-small>mdi-plus</v-icon>Add User
          </v-btn>
        </v-card-text>

        <v-divider />
        <v-card-actions class="mt-2">
          <v-btn v-if="hasUsers" depressed color="success" class="ml-5 mb-2" @click="showDialogAddEditUser()">
            <v-icon x-small class="pr-2">mdi-plus</v-icon>Add User
          </v-btn>
          <v-spacer />
          <slot name="footer-right"></slot>
          <v-btn depressed class="mr-5 mb-2" @click="closesDialogUsersList">Finished</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AppUserPermissionForm
      v-if="showDialogUserForm"
      :show-notice="loggedInUser.isAnonymous"
      :user="selectedUser"
      :can-update-email="selectedUser.email !== loggedInUser.email"
      @submit="addOrEditUser"
      @close="closeDialogUserForm"
    />

    <AppDialogConfirmDelete
      v-if="showDialogUserDelete"
      :message="deleteMessage"
      @confirm="deleteUser"
      @cancel="closeDialogUserDelete"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppVisibilityAlert from '@/components/shared/visibility/AppVisibilityAlert'
import AppUserPermissionForm from '@/components/shared/permissions/AppUserPermissionForm'

import DEFAULT_USER_PERMISSIONS from '@/enums/defaultUserPermissions'

export default {
  name: 'AppUserPermissionList',
  components: {
    AppVisibilityAlert,
    AppUserPermissionForm
  },
  props: {
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    entity: {
      type: String,
      required: true
    },
    entityName: {
      type: String,
      required: true
    },
    visibility: {
      type: String,
      required: true
    },
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    domains: {
      type: Array,
      default: () => ([])
    },
    users: {
      type: Array,
      default: () => ([])
    },
    disableDeleteForEmails: {
      type: Array,
      default: () => ([])
    },
    loggedInUserCanLeave: {
      type: Boolean,
      default: false
    },
    syncHandlesWithTeamwork: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDialogUsersList: false,
      showDialogUserForm: false,
      showDialogUserDelete: false,
      selectedUserIndex: -1,
      sortedUsers: [],
      whitelistedDomains: '',
      changeCount: 0
    }
  },
  computed: {
    hasUsers () {
      return this.sortedUsers.length > 0
    },
    whitelistedDomainsArray () {
      const domainString = this.whitelistedDomains.replace(/\s/g, '')
      return domainString.length > 0 ? domainString.split(',') : []
    },
    selectedUser () {
      return this.selectedUserIndex > -1 ? this.sortedUsers[this.selectedUserIndex] : {}
    },
    deleteMessage () {
      return this.canLeave(this.selectedUser) ? `You want to leave this ${this.entity.toLowerCase()}? ` : 'You want to remove this user?'
    }
  },
  watch: {
    twSyncOptions: {
      immediate: true,
      handler (options = {}) {
        if (!options.accessToken) return
        this.configAxios({ token: options.accessToken, isAPIKey: options.isAPIKey, apiEndPoint: options.apiEndPoint })
      }
    },
    domains: {
      immediate: true,
      handler (domains = []) {
        this.whitelistedDomains = domains.join(',')
      }
    },
    users: {
      immediate: true,
      handler: 'sortUsers'
    },
    'loggedInUser.email': {
      immediate: true,
      handler (email) {
        if (!email) return
        this.selectedUserIndex = this.sortedUsers.findIndex(u => u.email === this.loggedInUser.email)
        this.addOrEditUser(this.loggedInUser)
      }
    },
    visibility (visibility) {
      if (visibility !== 'private' || !this.loggedInUser.email) return
      this.selectedUserIndex = this.sortedUsers.findIndex(u => u.email === this.loggedInUser.email)
      if (this.selectedUserIndex === -1) this.addOrEditUser(this.loggedInUser)
    }
  },
  methods: {
    ...mapActions([
      'getUserByEmailId',
      'sendInvite',
      'configAxios'
    ]),
    openDialogUsersList () {
      this.showDialogUsersList = true
    },
    getNewUser (user) {
      return {
        email: user.email,
        displayName: user.displayName || '',
        photoURL: user.photoURL || '',
        handle: ''
      }
    },
    async sortUsers () {
      this.sortedUsers = this.users.slice()
      if (!this.hasUsers) return

      // 'Anonymous' to 'Guest User'
      this.sortedUsers.forEach(user => {
        if (user.email === 'anonymous@instantagencytools.com' && user.displayName === 'Anonymous') {
          user.displayName = 'Guest User'
          this.changeCount += 1
        }
      })

      const loggedInUserIndex = this.sortedUsers.findIndex(user => user.email === this.loggedInUser.email)
      // Add logged in user or update profile pic
      if (loggedInUserIndex === -1) {
        this.sortedUsers.push(this.getNewUser(this.loggedInUser))
      } else {
        this.sortedUsers[loggedInUserIndex].photoURL = this.loggedInUser.photoURL
      }

      // Get teamwork handle if syncing to assign todo/key priority to correct user
      // also we can show warning while assigning if user don't exists on teamwork
      // with given email address
      //
      if (this.syncHandlesWithTeamwork) this.sortedUsers = await this.checkAttendeeHandles(this.sortedUsers)

      // sort users
      this.sortedUsers.sort((a, b) => ('' + a.displayName).localeCompare(b.displayName))
      // only emit user list if atleast one user's handle/id has updated
      if (this.changeCount > 0) this.updatePemissions()
    },
    async checkAttendeeHandles (attendees) {
      const data = await Promise.all(attendees.map(attendee => this.fetchAttendeeHandle(attendee)))

      return attendees.map((attendee, index) => ({
        ...attendee,
        handle: data[index].handle,
        twId: data[index].twId
      }))
    },
    async fetchAttendeeHandle (attendee) {
      if (attendee.handle && attendee.twId) return Promise.resolve(attendee)
      // generate handle if user found
      const { data } = await this.getUserByEmailId(attendee.email)
      const user = !data.people || data.people.length === 0 ? {} : data.people[0]
      attendee.handle = user ? `${(user.firstName || '').toLowerCase()}${(user.lastName || '').charAt(0).toLowerCase()}` : ''
      if (user.id) this.changeCount += 1

      return Promise.resolve({ handle: attendee.handle, twId: user.id || '' })
    },
    closesDialogUsersList () {
      this.showDialogUsersList = false
      if (this.domains.join('') !== this.whitelistedDomainsArray.join('')) this.updatePemissions()
    },
    showDialogAddEditUser (index = -1) {
      this.selectedUserIndex = index
      this.showDialogUserForm = true
    },
    addOrEditUser (user) {
      const sendInvite = ![
        this.loggedInUser.email,
        this.selectedUserIndex > -1 ? this.sortedUsers[this.selectedUserIndex].email : ''
      ].includes(user.email) && !this.loggedInUser.isAnonymous

      user = this.getNewUser(user)

      if (this.selectedUserIndex === -1) {
        // Check that the email is not in use
        const emailIndex = this.sortedUsers.findIndex(u => u.email === user.email)
        if (emailIndex >= 0) {
          return this.$emit('set-message', { type: 'error', message: 'Email already used' })
        }
        this.sortedUsers.push(user)
      } else {
        this.$set(this.sortedUsers, this.selectedUserIndex, user)
      }

      this.updatePemissions()
      this.closeDialogUserForm()

      if (!sendInvite) return
      this.sendInvite({
        invitee: user.email,
        inviter: this.loggedInUser.displayName,
        resourceName: this.entityName,
        resourcePath: this.$route.fullPath.split('?')[0]
      })

      this.$analytics('user_invited', { email: user.email })
    },
    closeDialogUserForm () {
      this.showDialogUserForm = false
      this.selectedUserIndex = -1
    },
    confirmUserDelete (index) {
      this.selectedUserIndex = index
      this.showDialogUserDelete = true
    },
    deleteUser () {
      const leaving = this.canLeave(this.selectedUser)
      this.sortedUsers.splice(this.selectedUserIndex, 1)
      this.closeDialogUserDelete()
      this.updatePemissions(leaving)
      this.selectedUserIndex = -1
    },
    closeDialogUserDelete () {
      this.showDialogUserDelete = false
    },
    updatePemissions (redirect = false) {
      const users = this.sortedUsers
      const usersMeta = {}
      // update user meta
      users.forEach(user => {
        usersMeta[user.email] = DEFAULT_USER_PERMISSIONS
      })
      // update data
      this.$emit('update-permissions', {
        domains: this.whitelistedDomainsArray,
        usersMeta,
        users,
        redirect
      })
      this.changeCount = 0
    },
    canLeave (user) {
      return this.loggedInUserCanLeave && user.email === this.loggedInUser.email
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-permission__separator {
  width: 100%;
  border-bottom: 1px solid #eee;
  line-height: 0.1em;

  span {
    background:#fff;
  }
}

.eos-permission__list-item {
  height: 48px;
}
</style>
