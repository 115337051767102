<template>
  <v-card width="300">
    <v-card-title class="py-2">
      <template v-if="isInvited && isAnonymousUser">{{ accExists ? 'Sign in' : 'Account' }} Required</template>
      <template v-else>Access Denied</template>
    </v-card-title>
    <v-card-text class="pb-0">
      <p class="mt-2 mb-5">
        <template v-if="isInvited && isAnonymousUser">The {{ entity }} you are trying to access is private.</template>
        <template v-else>This {{ entity }} has either been <b>deleted</b> or made <b>private</b>.</template>
      </p>
      <p v-if="!isInvited" class="mt-2 mb-5">
        If you have just <b>restored</b> this {{ entity }} then it may take few minutes to perform the operation. Please try after few minutes.
      </p>
      <p v-if="isAnonymousUser" class="mt-2 mb-5">
        Please {{ accExists ? 'sign in' : 'create an account' }} to continue.
      </p>
      <p v-else class="mt-2 mb-5">
        Ask the {{ entity }} owner to add you to the attendees and try
        again.
      </p>
    </v-card-text>
    <v-card-actions class="mt-0 pt-0">
      <v-btn text color="primary" @click="$emit('reload')">Try again</v-btn>
      <v-spacer />
      <AppSignIn
        ref="authDialog"
        v-if="isAnonymousUser"
        :show-loader="showAuthLoader || checkingAccExists"
        :need-registration="!accExists"
        btn-color="primary"
        @login-with-google="$emit('login-with-google')"
        @login-with-microsoft="$emit('login-with-microsoft')"
        @login-with-email-pass="$emit('login-with-email-pass', $event)"
        @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
        @reset-password="$emit('reset-password', $event)"
        @update-password="$emit('update-password', $event)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import AppSignIn from '@/components/shared/auth/AppSignIn'

export default {
  name: 'AppErrorCard',
  components: { AppSignIn },
  props: {
    isAnonymousUser: {
      type: Boolean,
      default: false
    },
    isInvited: {
      type: Boolean,
      default: false
    },
    entity: {
      type: String,
      default: ''
    },
    showAuthLoader: {
      type: Boolean,
      default: false
    },
    inviteeEmail: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checkingAccExists: false,
      accExists: true
    }
  },
  async mounted () {
    if (this.isAnonymousUser && this.isInvited && this.inviteeEmail) {
      try {
        this.checkingAccExists = true
        const { data } = await this.getUserByEmail(this.inviteeEmail)
        if (data.code !== 'auth/user-not-found') return
        this.accExists = false
        this.$refs.authDialog.openDialog()
      } finally {
        this.checkingAccExists = false
      }
    }
  },
  methods: {
    ...mapActions(['getUserByEmail'])
  }
}
</script>

<style lang="scss" scoped>
.eos-privacy-card__login-btn:hover {
  background-color: #eee !important;
}
</style>
