<template>
  <v-dialog persistent v-model="dialog" max-width="360px">
    <v-form v-model="isValid" @submit.prevent="onSubmitForm">
      <v-card>
        <v-card-title class="headline" >
          <span class="mx-1" v-text="formTitle"></span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-text-field
            v-model.trim="form.displayName"
            autofocus
            outlined
            label="Name"
            class="mt-2"
            :rules="nameRules"
          />

          <v-text-field
            v-model.trim="form.email"
            label="Email"
            class="mt-2"
            outlined
            :rules="emailRules"
            :disabled="!canUpdateEmail"
          />

          <p v-if="!this.isEditing && showNotice" class="mb-0 text-caption">
            Please sign in to send invitation via email or just share the web page address above with your colleagues.
          </p>
        </v-card-text>

        <v-divider />
        <v-card-actions class="px-4 py-3">
          <v-spacer />
          <v-btn text @click="closeDialog">Cancel</v-btn>
          <v-btn type="submit" color="success" :disabled="!isValid" v-text="btnText" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'AppUserPermissionForm',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'User'
    },
    canUpdateEmail: {
      type: Boolean,
      default: false
    },
    showNotice: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true,
      isValid: false,
      nameRules: [
        v => !!v || 'Name is required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => EMAIL_VALIDATION_REGEX.test(String(v).toLowerCase()) || 'E-mail must be valid'
      ],
      form: Object.assign({
        displayName: '',
        email: '',
        handle: ''
      }, this.user)
    }
  },
  computed: {
    isEditing () {
      return Object.keys(this.user).length > 0
    },
    prefix () {
      return this.isEditing ? 'Update' : 'Add'
    },
    formTitle () {
      return `${this.prefix} ${this.title}`
    },
    btnText () {
      return this.prefix
    }
  },
  methods: {
    onSubmitForm () {
      this.form.email = this.form.email.toLowerCase()
      this.$emit('submit', this.form)
    },
    closeDialog () {
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
