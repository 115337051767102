<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :color="isPrivate ? '' : 'red'"
          text
          depressed
          class="pr-2"
          @click="onBtnClick"
        >
          <v-icon left>{{ icon }}</v-icon>
          <span>{{ visibilityText }}</span>
        </v-btn>
      </template>
      <span>{{ title }}</span>
    </v-tooltip>

    <AppDialogConfirmDelete
      v-if="showDialogConfirmPublic"
      :message="`You want to make this ${entity} public?`"
      @confirm="togglePrivacy"
      @cancel="showDialogConfirmPublic = false"
    />
  </div>
</template>

<script>
export default {
  name: 'AppVisibilityBtn.',
  props: {
    entity: {
      type: String,
      required: true
    },
    visibility: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showDialogConfirmPublic: false
    }
  },
  computed: {
    isPrivate () {
      return this.visibility === 'private'
    },
    icon () {
      return this.isPrivate ? 'mdi-lock' : 'mdi-lock-open'
    },
    title () {
      return this.isPrivate ? `Only permitted users can access the ${this.entity}` : `Anyone with an URL can access ${this.entity}`
    },
    visibilityText () {
      return this.isPrivate ? 'private' : 'public'
    }
  },
  methods: {
    onBtnClick () {
      this.isPrivate ? this.showDialogConfirmPublic = true : this.togglePrivacy()
    },
    togglePrivacy () {
      this.$emit('click')
      this.showDialogConfirmPublic = false
    }
  }
}
</script>
